import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import '@/assets/tailwind.css'
import Notifications from '@kyvg/vue3-notification'
import { createStore } from 'vuex'

const store = createStore({
  state () {
    return {
      user: null,
      auth: false
    }
  }
})


const app = createApp(App)

app
.use(store)
.use(Notifications)
.use(router)
.mount('#app')
