import { createRouter, createWebHistory } from 'vue-router'
import PainelView from '../views/PainelView.vue'
import AdminView from '../views/AdminView.vue'
import LoginView from '../views/LoginView.vue'
import { supabase } from '../lib/client'
import api from '@/api/axios'

const routes = [
  {
    path: '/dashboard',
    name: 'painel',
    component: PainelView,
  },
  {
    path: '/admin',
    name: 'admin',
    component: AdminView,
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView
  }
]

async function isAuthenticated(to, from, next) {
  // Verifique se o usuário está logado. Você pode implementar sua lógica de verificação aqui.
  const isLoggedIn = await supabase.auth.getSession()

  if (!isLoggedIn.data.session) {

    if(to.name == 'login'){
      next()
    }

    return next('/login');
  }

  if (to.name == 'login') {
    return next('/dashboard');
  }

  if (to.name == 'admin') {

    try {
      await api.isAdmin(isLoggedIn.data.session.access_token)

      return next();
    } catch {
      return next('/dashboard');
    }
  }

  if (routes.find(it => it.name == to.name)) {
    return next();
  }

  next('/login')

}

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  isAuthenticated(to, from, next);
});


export default router
