import axios from 'axios'

const url = "https://app.avanso.solutions"

const Main = {
    async getReportes(token) { 
        const { data } = await axios.post(`${url}/api/reportes`, {}, {
            headers: {
            'authorization': `Bearer ${token}`
            }
        })

        return data
    },
    async isAdmin(token) { 
        const { data } = await axios.post(`${url}/api/isadmin`, {}, {
            headers: {
            'authorization': `Bearer ${token}`
            }
        })

        return data
    },
    async getUsers(token) { 
        const { data } = await axios.post(`${url}/api/users`, {}, {
            headers: {
            'authorization': `Bearer ${token}`
            }
        })

        return data
    },
    async criarAtividade(token, email, titulo, descricao, link, tipo) { 
        const { data } = await axios.post(`${url}/api/criaratividade`, {
            email, titulo, descricao, link, tipo
        }, {
            headers: {
            'authorization': `Bearer ${token}`
            }
        })

        return data
    }
}

export default Main