<template>
  <div class="flex flex-col w-full min-h-screen" data-id="1">
    <navBar />
    <main class="flex-1 w-full overflow-hidden" data-id="15">
      <ul class="max-w-screen-xl mx-auto mt-36" v-if="atividades">
        <div class="text-zinc-300 mb-5 text-xl ml-2 lg:ml-0">Timeline</div>
        <div class="bg-zinc-900 border border-[#141414] shadow-md p-4" v-if="atividades?.length == 0">
          <div class="cursor-pointer text-white list-none p-2">
            <div class="mt-3 text-lg text-zinc-300">Ainda não há nenhuma atividade disponível na sua timeline.</div>
            <div class="mt-3 text-sm text-zinc-400">Aqui você acompanhará todas as atividades que envolvem o seu projeto!
            </div>
          </div>
        </div>
        <li class="mb-4" v-else>
          <details v-for="index in atividades" :key="index" class="bg-zinc-900 mb-3 border border-[#141414] shadow-md p-4">
            <summary class="cursor-pointer text-white list-none p-2">
              <span class="border border-green-300 text-sm text-green-300 px-1 py-0.5" v-if="index.tipo == 'atualizacao'"> Atualização
              </span>
              <span class="border border-orange-300 text-sm text-orange-300 px-1 py-0.5" v-if="index.tipo == 'planejamento'"> Planejamento
              </span>
              <span class="text-zinc-500 px-2 rounded float-right">{{ new Date(index.createdAt).toLocaleString('pt-br')}}</span>
              <div class="mt-3 text-lg text-zinc-300">{{index.titulo}}</div>
            </summary>
            <div class="p-2">
              <div class="mt-3 text-zinc-400 mb-4">{{ index.descricao }}</div>
              <span class="text-sm text-[#5afaff] rounded" v-if="index.tipo == 'planejamento'"><a :href="index.link" target="_blank">Visualizar planejamento</a></span>
              <span class="text-sm text-[#5afaff] rounded" v-if="index.tipo == 'atualizacao'"><a :href="index.link" target="_blank">Download arquivos</a></span>
            </div>
          </details>
        </li>
      </ul>
      <div v-else class="mt-64">
        <div class="flex justify-center items-center space-x-1 text-sm text-zinc-500">
          <svg fill='none' class="w-6 h-6 animate-spin" viewBox="0 0 32 32" xmlns='http://www.w3.org/2000/svg'>
            <path clip-rule='evenodd'
              d='M15.165 8.53a.5.5 0 01-.404.58A7 7 0 1023 16a.5.5 0 011 0 8 8 0 11-9.416-7.874.5.5 0 01.58.404z'
              fill='currentColor' fill-rule='evenodd' />
          </svg>
          <div>Carregando as informações ...</div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
// @ is an alias to /src
import { supabase } from '../lib/client'
import navBar from '@/components/navBar.vue'
import api from '@/api/axios'

export default {
  name: 'painelView',
  components: {
    navBar
  },
  data() {
    return {
      atividades: null
    }
  },
  async mounted() {
    const { data } = await supabase.auth.getSession()

    const reportes = await api.getReportes(data.session.access_token)

    this.atividades = reportes.data.reverse()
  }
}
</script>
