<template>
  <header>
    <nav class="border-b-zinc-200 fixed w-full px-4 lg:px-6 py-5 bg-zinc-900">
      <div class="flex flex-wrap justify-between items-center mx-auto max-w-screen-xl">
        <div class="flex items-center">
          <a href="/"><img src="https://avanso.com.br/images/solutions.png" class="mr-3 h-12" /></a>
        </div>
        <div class="flex items-center px-5" v-if="user.email">
          <div class="flex-shrink-0" v-if="user.img">
            <img class="h-10 w-10 rounded-full p-0.5" :src="user.img" alt="">
          </div>
          <div class="ml-3 pr-5 hidden lg:block">
            <div class="text-base font-medium leading-none text-zinc-300">{{ user.name }}</div>
            <div class="text-sm font-medium leading-none text-[#5afaff]">{{ user.email }}</div>
          </div>
          <div class="ml-5">
            <a href="https://api.whatsapp.com/send?1=pt_BR&phone=551930941249" target="_blank" class="text-base cursor-pointer font-medium leading-none text-zinc-400">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24"><path d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm1.25 17c0 .69-.559 1.25-1.25 1.25-.689 0-1.25-.56-1.25-1.25s.561-1.25 1.25-1.25c.691 0 1.25.56 1.25 1.25zm1.393-9.998c-.608-.616-1.515-.955-2.551-.955-2.18 0-3.59 1.55-3.59 3.95h2.011c0-1.486.829-2.013 1.538-2.013.634 0 1.307.421 1.364 1.226.062.847-.39 1.277-.962 1.821-1.412 1.343-1.438 1.993-1.432 3.468h2.005c-.013-.664.03-1.203.935-2.178.677-.73 1.519-1.638 1.536-3.022.011-.924-.284-1.719-.854-2.297z"/></svg>
            </a>
          </div>
          <div class="ml-5">
            <span @click="logout" class="text-base cursor-pointer font-medium leading-none text-zinc-400">
              <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" fill="currentColor" fill-rule="evenodd" clip-rule="evenodd"><path d="M11 21h8v-2l1-1v4h-9v2l-10-3v-18l10-3v2h9v5l-1-1v-3h-8v18zm10.053-9l-3.293-3.293.707-.707 4.5 4.5-4.5 4.5-.707-.707 3.293-3.293h-9.053v-1h9.053z"/></svg>
            </span>
          </div>
        </div>
      </div>
    </nav>
  </header>
</template>

<script>

import { supabase } from '../lib/client'

export default {
  data() {
    return {
      user: {
        email: null,
        img: null,
        name: null
      }
    }
  },
  async mounted() {

    const { data } = await supabase.auth.getSession()

    if (data) {
      this.user = {
        email: data.session.user.user_metadata.email,
        img: data.session.user.user_metadata.avatar_url,
        name: data.session.user.user_metadata.name
      }
    }
  },
  methods: {
    async logout(){
      await supabase.auth.signOut()
      location.reload()
    }
  }
}
</script>

