<template>
  <div class="flex flex-col w-full min-h-screen" data-id="1">
    <navBar />
    <main class="flex-1 w-full overflow-hidden" data-id="15">
      <ul class="max-w-screen-xl mx-auto mt-36" v-if="dados">
        <div class="py-6 sm:py-8 lg:py-12">
          <div class="mx-auto max-w-screen-2xl px-4 md:px-8">
            <h2 class="mb-4 text-center text-2xl text-zinc-200 md:mb-8 lg:text-3xl">Criar atividade</h2>

            <div class="mx-auto bg-zinc-900 max-w-lg">
              <div class="flex flex-col gap-4 p-4 md:p-8">
                <div>
                  <label for="email" class="mb-2 inline-block text-sm text-zinc-200 sm:text-base">
                    Usuário:</label>

                  <select v-model="email"
                    class="w-full rounded border border-zinc-500 bg-zinc-900 px-3 py-2 text-zinc-200 outline-none ring-indigo-300 transition duration-100 focus:ring">
                    <option v-for="index in dados" :key="index" :value="index._id">{{ index.email }} - {{ index.name }}
                    </option>
                  </select>
                </div>

                <div>
                  <label class="mb-2 inline-block text-sm text-zinc-200 sm:text-base">
                    Tipo:</label>

                  <select
                  v-model="tipo"
                    class="w-full rounded border border-zinc-500 bg-zinc-900 px-3 py-2 text-zinc-200 outline-none ring-indigo-300 transition duration-100 focus:ring">
                    <option value="atualizacao">Atualização</option>
                    <option value="planejamento">Planejamento</option>
                  </select>
                </div>

                <div>
                  <label class="mb-2 inline-block text-sm text-zinc-200 sm:text-base">
                    Titulo:</label>

                  <input type="text" v-model="titulo" class="w-full rounded border border-zinc-500 bg-zinc-900 px-3 py-2 text-zinc-200 outline-none ring-indigo-300 transition duration-100 focus:ring" />
                </div>

                <div>
                  <label class="mb-2 inline-block text-sm text-zinc-200 sm:text-base">
                    Descrição:</label>

                  <textarea v-model="descricao" class="w-full rounded border border-zinc-500 bg-zinc-900 px-3 py-2 text-zinc-200 outline-none ring-indigo-300 transition duration-100 focus:ring"></textarea>
                </div>

                <div>
                  <label class="mb-2 inline-block text-sm text-zinc-200 sm:text-base">
                    Link:</label>

                  <input type="text" v-model="link" class="w-full rounded border border-zinc-500 bg-zinc-900 px-3 py-2 text-zinc-200 outline-none ring-indigo-300 transition duration-100 focus:ring" />
                </div>

                <span @click="criar" class="w-full text-black rounded-lg mt-3 bg-cyan-200 text-center p-2">Criar atividade</span>

              </div>
            </div>
          </div>
        </div>
      </ul>
      <div v-else class="mt-64">
        <div class="flex justify-center items-center space-x-1 text-sm text-zinc-500">
          <svg fill='none' class="w-6 h-6 animate-spin" viewBox="0 0 32 32" xmlns='http://www.w3.org/2000/svg'>
            <path clip-rule='evenodd'
              d='M15.165 8.53a.5.5 0 01-.404.58A7 7 0 1023 16a.5.5 0 011 0 8 8 0 11-9.416-7.874.5.5 0 01.58.404z'
              fill='currentColor' fill-rule='evenodd' />
          </svg>
          <div>Carregando as informações ...</div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
// @ is an alias to /src
import navBar from '@/components/navBar.vue'
import api from '@/api/axios'
import { supabase } from '../lib/client'

export default {
  name: 'AdminView',
  components: {
    navBar
  },
  data() {
    return {
      dados: null,
      tipo: null,
      titulo: null,
      descricao: null,
      link: null,
      email: null
    }
  },
  async mounted() {
    const { data } = await supabase.auth.getSession()

    const users = await api.getUsers(data.session.access_token)

    this.dados = users.data
  },

  methods: {
    async criar() {

      if(!this.tipo || !this.titulo || !this.descricao || !this.link || !this.email){
        return alert("Preencha todos os campos")
      }

      const { data } = await supabase.auth.getSession()

      await api.criarAtividade(data.session.access_token, this.email, this.titulo, this.descricao, this.link, this.tipo)

      alert('Cadastrado com sucesso')

      location.reload()
    }
  }
}
</script>
