<template>
  <router-view />
</template>

<script>
// @ is an alias to /src
export default {

}

</script>

<style>
details summary::-webkit-details-marker {
        display: none;
      }
html {
  font-family: "Satoshi", ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"; /* 1 */
  line-height: 1.5; /* 2 */
}
</style>
